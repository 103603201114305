import React from 'react';
import Box from '../Box';
import { themeGet } from '../../../utils';

const defaultProps = ({ size, fontSize, mt, mb, m, ...rest }) => {
  const fontSizeDefault = () => {
    if (fontSize === null) return null;
    if (fontSize !== undefined) return fontSize;
    if (size === 'xl') {
      return themeGet('fontSizes.xl');
    }
    if (size === 'm') {
      return themeGet('fontSizes.m');
    }
    return null;
  };

  return {
    fontSize: fontSizeDefault(),
    m: m !== undefined ? m : null,
    mt: m === undefined ? mt : null,
    mb: m === undefined ? mb : null,
    ...rest,
  };
};

const Text = props => <Box {...defaultProps(props)} />;

Text.defaultProps = {
  as: 'p',
  mt: 0,
  mb: 28,
  size: 'l',
};

Text.displayName = 'Text';

export default Text;
