import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { style } from 'styled-system';
import Box from '../Box';

const flex = style({
  prop: 'width',
  cssProperty: 'flex',
  transformValue(n) {
    if (n === null) return null;
    switch (n) {
      case 'auto':
        return '1 1 0%';
      case 'contains':
        return '0 0 auto';
      default:
        return `0 0 ${n * 100}%;`;
    }
  },
});

const maxWidth = style({
  prop: 'width',
  cssProperty: 'maxWidth',
  transformValue(n) {
    if (n === null) return null;
    switch (n) {
      case 'auto':
      case 'contains':
        return '100%';
      default:
        return `${n * 100}%;`;
    }
  },
});

const Col = styled(({ width, ...rest }) => <Box {...rest} />)`
  ${flex};
  ${maxWidth};
`;

Col.propTypes = {
  width: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
};

Col.defaultProps = {
  width: 'auto',
  position: 'relative',
};

Col.displayName = 'Col';

export default Col;
