import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { themeGet } from '../../../utils';

const styles = css`
  display: inline-block;
  vertical-align: top;
  touch-action: manipulation;
  text-transform: lowercase;
  text-align: center;
  font-weight: 400;
  transition: 0.15s ease-in-out;
  font-size: ${themeGet('fontSizes.m')}px;
  line-height: 1.2;
  color: #fff;
  background: ${({ color }) => themeGet(`colors.${color}`)};
  border: 1px solid transparent;
  padding: 9px 23px 11px;
  cursor: pointer;
  outline: none;

  &:hover {
    background: #fff;
    color: ${themeGet('colors.black')};
    border-color: ${themeGet('colors.black')};
  }
`;

const StyledButton = styled.button`
  ${styles};
`;
const StyledLink = styled.a`
  ${styles};
`;
const StyledNavLink = styled(Link)`
  ${styles};
`;

const Button = ({ to, href, type, ...rest }) => {
  if (to) {
    return <StyledNavLink to={to} {...rest} />;
  }

  if (href) {
    return <StyledLink href={href} rel="noopener noreferrer nofollow" target="_blank" {...rest} />;
  }

  return <StyledButton type={type} {...rest} />;
};

Button.propTypes = {
  color: PropTypes.oneOf(['cta', 'black']),
  type: PropTypes.oneOf(['submit', 'button']),
  href: PropTypes.string,
  to: PropTypes.string,
};

Button.defaultProps = {
  color: 'black',
  type: 'button',
};

export default Button;
