import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  textStyle,
  colorStyle,
  buttonStyle,
} from 'styled-system';

const StyledBox = styled.div`
  ${space};
  ${color};
  ${typography};
  ${({ size, ...props }) => layout(props)};
  ${flexbox};
  ${grid};
  ${background};
  ${border};
  ${position};
  ${shadow};
  ${textStyle};
  ${colorStyle};
  ${buttonStyle};
`;

const Box = ({ tag, as, ...props }) => {
  const getTag = () => {
    if (tag) return tag;
    if (as) return as;
    return 'div';
  };
  return <StyledBox {...props} as={getTag()} />;
};

Box.propTypes = {
  tag: PropTypes.string,
  as: PropTypes.string,
};

Box.displayName = 'Box';

export default Box;
