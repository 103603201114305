import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from '../Box';

const Divider = styled(Box)`
  position: relative;
`;

Divider.propTypes = {
  ...Box.propTypes,
  bg: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
};

Divider.defaultProps = {
  bg: 'grayscale.200',
  width: 'auto',
  height: '1px',
};

export default Divider;
