import React from 'react';
import styled from 'styled-components';
import { themeGet } from '../../../utils';
import bubble from '../../../assets/img/bubble.svg';

const StyledBody = styled.div`
  min-height: 100vh;
  color: ${themeGet('colors.black')};
  font-family: ${themeGet('fonts.bodyText')};
  font-size: ${themeGet('fontSizes.l')}px;
  line-height: ${themeGet('lineHeights.l')};
  background: url("${bubble}") no-repeat 0 0;
`;

const Body = props => <StyledBody {...props} />;

export default Body;
