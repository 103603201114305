import React from 'react';
import Box from '../Box';
import { themeGet } from '../../../utils';

const defaultProps = ({ size, fontSize, lineHeight, mt, mb, m, ...rest }) => {
  const fontSizeDefault = () => {
    if (fontSize === null) return null;
    if (fontSize !== undefined) return fontSize;

    const h1 = themeGet('fontSizes.heading.h1');
    const h2 = themeGet('fontSizes.heading.h2');
    const h3 = themeGet('fontSizes.heading.h3');
    const h4 = themeGet('fontSizes.heading.h4');

    switch (size) {
      case 'h1':
        return [h2, h1];
      case 'h2':
        return [h3, h2];
      case 'h3':
        return [h4, h3];
      case 'h4':
        return h4;
      default:
        return h4;
    }
  };

  const lineHeightDefault = () => {
    if (lineHeight === null) return null;
    if (lineHeight !== undefined) return lineHeight;

    const h1 = themeGet('lineHeights.heading.h1');
    const h2 = themeGet('lineHeights.heading.h2');
    const h3 = themeGet('lineHeights.heading.h3');
    const h4 = themeGet('lineHeights.heading.h4');

    switch (size) {
      case 'h1':
        return [h2, h1];
      case 'h2':
        return [h3, h2];
      case 'h3':
        return [h4, h3];
      case 'h4':
        return h4;
      default:
        return null;
    }
  };

  return {
    fontSize: fontSizeDefault(),
    lineHeight: lineHeightDefault(),
    m: m !== undefined ? m : null,
    mt: m === undefined ? mt : null,
    mb: m === undefined ? mb : null,
    ...rest,
  };
};

const Title = props => <Box {...defaultProps(props)} />;

Title.defaultProps = {
  as: 'h2',
  size: 'h2',
  mt: 0,
  mb: 'xxl',
  fontWeight: 'bold',
};

Title.displayName = 'Title';

export default Title;
