import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '../Box';
import Row from '../Row';

const StyledContainer = styled(Box)`
  position: relative;
  box-sizing: content-box;
`;

const Container = ({ children, row, withRow, ...props }) => (
  <StyledContainer {...props}>
    {withRow ? <Row {...row}>{children}</Row> : children}
  </StyledContainer>
);

Container.propTypes = {
  px: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  mx: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  withRow: PropTypes.bool,
  row: PropTypes.shape(),
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  px: ['m', 'l', 'xl'],
  mx: 'auto',
  maxWidth: 'l',
  withRow: false,
  row: {},
};

Container.displayName = 'Container';

export default Container;
