import React from 'react';
import Box from '../Box';

const Flex = props => <Box {...props} />;

Flex.defaultProps = {
  display: 'flex',
};

Flex.displayName = 'Flex';

export default Flex;
