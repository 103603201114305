import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { style } from 'styled-system';
import Box from '../Box';
import { themeGet } from '../../../utils';

const marginLeft = style({
  prop: 'gap',
  cssProperty: 'marginLeft',
  transformValue(n) {
    if (n === null || n === 0) return null;
    return -themeGet(`space.${n}`, n);
  },
});

const StyledRow = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  ${marginLeft};
`;

const Row = ({ children, gap, ...rest }) => (
  <StyledRow gap={gap} {...rest}>
    {React.Children.map(children, child => React.cloneElement(child, { pl: gap || null }))}
  </StyledRow>
);

Row.propTypes = {
  children: PropTypes.node.isRequired,
  gap: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
};

Row.defaultProps = {
  gap: 'l',
};

export default Row;
