import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { space, layout, color as styledColor } from 'styled-system';

const styles = css`
  ${space};
  ${layout};
  ${styledColor};
  ${({ underline }) => (underline ? 'text-decoration: underline' : null)};

  &:hover {
    text-decoration: none;
  }
`;

const StyledNavLink = styled(GatsbyLink)`
  ${styles};
`;
const StyledLink = styled.a`
  ${styles};
`;

const Link = ({ to, href, underline, target, ...props }) => {
  if (to) {
    return <StyledNavLink to={to} {...props} />;
  }
  if (href) {
    return (
      <StyledLink
        href={href}
        underline={underline}
        target={target}
        rel="noopener noreferrer nofollow"
        {...props}
      />
    );
  }
  return <span {...props} />;
};

Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  underline: PropTypes.bool,
  target: PropTypes.string,
};

Link.defaultProps = {
  underline: false,
  target: '_blank',
};

Link.displayName = 'Link';

export default Link;
