import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function SEO({ description, lang, meta, title, slug }) {
  const url = `${process.env.GATSBY_BASE_URL}${slug}`;
  const coverImage = `${process.env.GATSBY_BASE_URL}/cover.v1.png`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: coverImage,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: coverImage,
        },
        {
          name: `twitter:url`,
          content: url,
        },
        {
          name: `twitter:creator`,
          content: '@heartbeat_ua',
        },
        {
          name: `twitter:card`,
          content: 'summary',
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  description: '',
  title: '',
  slug: '',
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  slug: PropTypes.string,
};

export default SEO;
