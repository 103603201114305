import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Box from '../Box';
import { themeGet } from '../../../utils';

const StyledList = styled(Box)`
  list-style-position: inside;

  li {
    margin-bottom: ${themeGet('space.xs')}px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    display: inline;
  }
`;

const List = ({ as, ...rest }) => <StyledList tag={as} {...rest} />;

List.propTypes = {
  as: PropTypes.string,
  tag: PropTypes.string,
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

List.defaultProps = {
  as: 'ul',
  mt: 0,
  mx: 'xs',
  mb: 28,
  p: 0,
};

export default List;
