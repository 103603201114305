const colors = {
  black: '#191919',
  white: '#fff',
  brand: '#62e6ac',
  cta: '#ff8c5f',
  link: '#1755b7',
  error: '#f44',
  grayscale: {
    200: '#eaeaea',
    500: '#d2d2d2',
    800: '#7d7d7d',
  },
};

const fonts = {
  bodyText: 'futura-pt, sans-serif',
};

const fontSizes = {
  m: 20,
  l: 23,
  xl: 29,
  heading: {
    h1: 101,
    h2: 79,
    h3: 47,
    h4: 38,
  },
};

const lineHeights = {
  l: 1.34,
  heading: {
    h1: '107px',
    h2: '83.75px',
    h3: '60px',
    h4: '44px',
  },
};

const sizes = {
  xs: 576,
  s: 768,
  m: 992,
  l: 1210,
};

const breakpoints = Object.values(sizes).map(curr => `${curr}px`);

// eslint-disable-next-line no-restricted-syntax
for (const [key, value] of Object.entries(sizes)) {
  breakpoints[key] = `${value}px`;
}

const space = {
  xs: 8,
  s: 16,
  m: 24,
  l: 32,
  xl: 40,
  xxl: 48,
};

module.exports = {
  colors,
  fonts,
  fontSizes,
  lineHeights,
  breakpoints,
  space,
  sizes,
};
