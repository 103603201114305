import React from 'react';
import styled from 'styled-components';
import { typography, space } from 'styled-system';
import { themeGet } from '../../../utils';

const Button = styled.a`
  display: block;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  outline: none;
  user-select: none;
  transition: 0.1s ease;
  border-radius: 0;
  text-transform: lowercase;
  width: 100%;
  color: #fff;
  font-weight: bold;
  background: ${themeGet('colors.cta')};
  border: 1px solid ${themeGet('colors.cta')};
  ${() =>
    typography({
      fontSize: [25, null, themeGet('fontSizes.heading.h2')],
      lineHeight: [null, null, themeGet('lineHeights.heading.h2')],
    })};
  ${() => space({ padding: ['16px 10px', null, '94px 25px'] })};

  &:hover {
    border-color: ${themeGet('colors.black')};
    background: ${themeGet('colors.black')};
  }
`;

const CtaButton = () => {
  return <Button href="/process">Let me know the process</Button>;
};

export default CtaButton;
