import React from 'react';
import styled from 'styled-components';
import { layout, border } from 'styled-system';
import PropTypes from 'prop-types';

const Img = styled.img`
  ${layout};
  ${border};
`;

const Image = ({ src, alt, fluid, aspectRatio, ...rest }) => {
  return <Img src={src} alt={alt} maxWidth={fluid ? '100%' : null} {...rest} />;
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  fluid: PropTypes.bool,
  aspectRatio: PropTypes.number,
  maxWidth: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
};

Image.defaultProps = {
  fluid: true,
  alt: '',
};

Image.displayName = 'Image';

export default Image;
