import React from 'react';
import styled from 'styled-components';
import { Box } from '../../atoms';
import MenuItem from './MenuItem';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`;

const Menu = () => (
  <Box as="nav" display={['none', null, 'block']}>
    <List>
      <li>
        <MenuItem href={`${process.env.GATSBY_WEBSITE}/projects`}>work</MenuItem>
      </li>
      <li>
        <MenuItem href={`${process.env.GATSBY_WEBSITE}/team`}>team</MenuItem>
      </li>
      <li>
        <MenuItem href={`${process.env.GATSBY_WEBSITE}/process`}>process</MenuItem>
      </li>
      <li>
        <MenuItem href={`${process.env.GATSBY_WEBSITE}/quiz`} cta="true">
          let&apos;s talk
        </MenuItem>
      </li>
    </List>
  </Box>
);

export default Menu;
