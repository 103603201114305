import React from 'react';
import styled from 'styled-components';
import { themeGet } from '../../../utils';

const StyledItem = styled.a`
  width: 140px;
  display: block;
  position: relative;
  margin-right: -1px;
  padding-top: 8px;
  padding-bottom: 11px;
  text-align: center;
  transition: 0.1s ease-out;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  border: 1px solid ${({ cta }) => themeGet(`colors.${cta ? 'cta' : 'grayscale.500'}`)};
  background: ${({ cta }) => themeGet(`colors.${cta ? 'cta' : 'white'}`)};
  color: ${({ cta }) => themeGet(`colors.${cta ? 'white' : 'black'}`)};
  font-size: ${themeGet('fontSizes.m')}px;

  &:hover {
    z-index: 1;
    color: ${themeGet('colors.white')};
    background-color: ${themeGet('colors.black')};
    border-color: ${themeGet('colors.black')};
  }

  @media (min-width: 992px) {
    width: 180px;
  }
`;

const MenuItem = props => <StyledItem {...props} />;

export default MenuItem;
