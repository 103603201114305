import React from 'react';
import styled from 'styled-components';
import { Flex, Box, Container, Link, Button } from '../../atoms';
import Menu from '../../molecules/Menu';
import { ReactComponent as LogoSVG } from '../../../assets/img/logo.svg';

const Wrapper = styled(Box)`
  position: relative;
`;

const Header = () => (
  <Wrapper pr={[null, null, 16]}>
    <Container maxWidth={null}>
      <Flex alignItems="center" height="156px" justifyContent="space-between">
        <div>
          <Link href={process.env.GATSBY_WEBSITE} target={null}>
            <LogoSVG />
          </Link>
        </div>
        <div>
          <Menu />
          <Box display={['block', null, 'none']}>
            <Button skin="outline">menu</Button>
          </Box>
        </div>
      </Flex>
    </Container>
  </Wrapper>
);

export default Header;
