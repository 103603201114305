import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Body } from '../components/atoms';
import CtaButton from '../components/molecules/CtaButton';
import Header from '../components/organisms/Header';
import theme from '../theme';
import '../assets/css/base.css';

const Base = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Body>
        <Header />
        {children}
        <CtaButton />
      </Body>
    </ThemeProvider>
  );
};

Base.propTypes = {
  children: PropTypes.node,
};

export default Base;
