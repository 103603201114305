import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { layout, position, space, color, typography } from 'styled-system';

import { ReactComponent as IconFacebook } from './icons/facebook.svg';
import { ReactComponent as IconTwitter } from './icons/twitter.svg';
import { ReactComponent as IconLinkedin } from './icons/linkedin.svg';

const styles = css`
  ${space};
  ${color};
  ${position};
  ${typography};
  ${({ va, ...props }) => layout({ ...props, verticalAlign: va })};
  ${({ name }) => name === 'arrowUp' && 'transform: rotate(180deg);'};
`;

const getIcon = name => {
  switch (name) {
    case 'facebook':
      return IconFacebook;
    case 'twitter':
      return IconTwitter;
    case 'linkedin':
      return IconLinkedin;
    default:
      return null;
  }
};

const StyledIcon = styled(({ component, ...props }) => React.createElement(component, props))`
  ${styles};
`;

const Icon = ({ name, verticalAlign, ...props }) => {
  return <StyledIcon {...props} va={verticalAlign} name={name} component={getIcon(name)} />;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  position: PropTypes.string,
  display: PropTypes.string,
  verticalAlign: PropTypes.string,
};

Icon.defaultProps = {
  position: 'relative',
  color: 'black',
  display: 'inline-block',
  verticalAlign: 'middle',
};

export default Icon;
