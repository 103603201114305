import at from 'lodash/at';
import pick from 'lodash/pick';
import theme from './theme';

const themeGet = (path, fallback) => {
  const value = at(theme, path)[0];
  if (value) {
    return value;
  }

  if (fallback === undefined) {
    // eslint-disable-next-line no-console
    console.warn(`"${path}" doesn't exist`);
    return null;
  }

  return fallback;
};

const styledSpaces = spaces =>
  pick(spaces, ['m', 'mx', 'my', 'mt', 'mr', 'mb', 'ml', 'p', 'px', 'py', 'pt', 'pr', 'pb', 'pl']);

const truncateString = (str, num) => {
  if (str.length <= num) return str;
  return `${str.slice(0, num)}…`;
};

export { themeGet, styledSpaces, truncateString };
